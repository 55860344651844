<template>
  <Dialog
    class="dialog"
    title="温馨提示"
    :showConfirmBtn="false"
    content="您的订单满足促销政策，可以半价购买特利功能包,是否购买此促销产品"
  >
    <template #btn>
      <div class="btn-wrap">
        <div class="btn" @click="$emit('addbag')">特利功能包</div>
        <div class="btn" @click="$emit('addbox')">特利功能包（盒装）</div>
        <div class="btn" @click="$emit('cancel')">取消</div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/common/dialog/index.vue'

export default {
  components: { 
    Dialog,
  },

}
</script>

<style lang="scss" scoped>
.dialog {
  .btn-wrap {
    padding-bottom: 14px;
    .btn {
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #CD001F;
    }
  }
}
</style>