<template>
  <div class="order-index-page">
    <Header 
      type="add" 
      :icons="[{icon: FilterIcon, event: 'filter'}, {icon: AddIcon, event: 'add'}]"
      @filter="showFilter=true"
      @add="$router.push({name: 'OrderAddOrderFirst', params: {seller: 'self'}})"
    />
    <div class="card-list">
      <Card class="card" v-for="(item, index) in list" :key="index" :content="item" :hasStatus="true" :status="status[index]" >
        <div class="detial-btn-wrap">
          <CustomButton class="detail-btn" size="mini" @click="$router.push({path: `/order/detail?id=${idList[index]}&date=${originList[index].addDate}&sapcode=${originList[index].sapOrderCode}`})">详情</CustomButton>
        </div>
      </Card>
    </div>
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span class="no-more" slot="no-more">
        暂无更多
      </span>
    </infinite-loading>
    <FilterOrder
      v-if="showFilter"
      v-model="filterData"
      @confirm="handleFilterConfirm"
    >
    </FilterOrder>
  </div>
</template>

<script>
import Header from '@/components/header/headerWithIcon/index.vue'
import Card from '@/components/common/card/index.vue'
import AddIcon from '@/assets/images/common/add.png'
import FilterIcon from '@/assets/images/common/filter-white.png'
import CustomButton from '@/components/common/button/CustomButton.vue'
import InfiniteLoading from 'vue-infinite-loading'
import { fetchOrderList } from '@/api/order'
import FilterOrder from './filterOrder.vue'
import dayjs from 'dayjs'


export default {
  components: {
    Header,
    Card,
    CustomButton,
    InfiniteLoading,
    FilterOrder,
  },
  data() {
    return {
      AddIcon,
      FilterIcon,
      status: [],
      idList: [],
      showFilter: false,
      filterData: { company: '', date: '', status: ''},
      config:[
        {
          label: '订单号',
          field: 'sapOrderCode',
          color: '#2C2C2C',
        },
        {
          label: '订单日期',
          field: 'addDate',
          color: '#2C2C2C'
        },
        {
          label: '业务员',
          field: 'personName',
          color: '#2C2C2C'
        },
        {
          label: '经销商',
          field: 'sellerName',
          color: '#2C2C2C'
        },
        {
          label: '经销商编号',
          field: 'sellerId',
          color: '#2C2C2C'
        },
      ],
      list: [],
      pageNo: 1,
      pageSize: 10,
      total: 0, 
      originList: []
    }
  },
  deactivated() {
    this.resetList()
  },
  activated() {
    this.resetList()
  },
  methods: {
    resetList() {
      this.showFilter = false;
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.filterData = []
      this.pageNo = 1;
      this.total = 0;
    },
    handleFilterConfirm() {
      this.showFilter = false;
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = []
      this.originList = []
      this.pageNo = 1;
      this.total = 0;
    },
    async infiniteHandler($state) {
      await this.fetchList();
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    async fetchList() {
      let response = []
      let data = {
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        "factory": this.filterData.company,
        "addDateStart": this.filterData.date && this.filterData.date[0] ? dayjs(this.filterData.date[0]).format('YYYYMMDD') : '',
        "addDateEnd": this.filterData.date && this.filterData.date[1] ? dayjs(this.filterData.date[1]).format('YYYYMMDD') : '',
        "orderStatus": this.filterData.status
      }
      response = await fetchOrderList(data)
      this.list = this.list.concat(this.formatList(response.data) || []);
      this.originList = this.originList.concat(response.data || [])
      this.total = (response.page && response.page.total) || 0;
    },
    formatList(data) {
      let ret = []
      let status = this.status
      let idList = this.idList
      data.map && data.map(dataItem=>{
        let oneItem = []
        this.config.map(item=>{
          oneItem.push({
            ...item,
            value: dataItem[item.field],
          })
        })
        status.push({text: dataItem.orderStatus, color: '#CD001F'})
        idList.push(dataItem.rowId)
        ret.push(oneItem)

      })
      this.status = status
      this.idList = idList
      return ret
    }
  }
}
</script>

<style lang="scss" scoped>
.order-index-page {
  background: #FAFBFC;
  width: 100%;
  min-height: 100vh; 
  box-sizing: border-box;
  .card-list {
    width: 100%;
    box-sizing: border-box;
    padding: 0 12px;
    margin-top: 12px;
    .card {
      margin-bottom: 12px;
      .detial-btn-wrap {
        text-align: right;
        .detial-btn {
        }
      }
    }
  }
  .no-more {
    font-size: 14px;
    color: #323232;
  }
}
</style>