<template>
  <div class="detail-select-page">
    <Header text="确定" @textClick="handleConfirm" />
    <div class="form-wrap">
      <Form  :rows="rows" v-model="form" />
    </div>
    <div class="two-btn-container">
      <div class="btn-wrap">
        <CustomButton size="large" @click="reset">重置</CustomButton>
      </div>
      <div class="btn-wrap">
        <CustomButton size="large-plain" @click="confirm">查询</CustomButton>
      </div>
    </div>
    <BlockImageSelectList v-model="choose" :setCodeList="setCodeList" :single="single" :chooseList="value" :checkPrice="checkPrice" :list="list" :params="params" activeField="materialCode" @loading="handleChangeLoading" />
    <!-- 无限加载 -->
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more">
        <!-- 暂无更多 -->
      </span>
    </infinite-loading>
    <div class="loading-container" v-if="isLoading">
      <Loading color="#cd001f" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/headerWithText/index.vue'
import Form from '@/components/common/form/index.vue'
import CustomButton from '@/components/common/button/CustomButton.vue'
import BlockImageSelectList from './components/orderBlockImageSelectList.vue'
import InfiniteLoading from 'vue-infinite-loading'
import { fetchProductList, fetchProductTypeList } from '@/api/select'
import { Loading } from 'vant'

export default {
  components: {
    Header,
    Form,
    CustomButton,
    BlockImageSelectList,
    InfiniteLoading,
    Loading,
  },
  props: {
    value: {},
    params: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    single: {
      type: Boolean,
      default: false
    },
    checkPrice: {
      type: Boolean,
      default: true
    },
    setCodeList: {
      type: Array,
      default: ()=>{
        return []
      }
    }
  },
  data() {
    return {
      choose: [],
      rows: [
        {label: '产品分类', key: 'classify', type: 'picker', valueString: 'dictValue', labelString: 'dictLabel'},
        {label: '物料编码', key: 'code', type: 'input'},
        {label: '物料名称', key: 'name', type: 'input'},
      ],
      list: [],
      pageNo: 1,
      pageSize: 10,
      total: 0,
      form: {},
      isLoading: false,
    }
  },
  beforeMount() {
    this.fetchProductType()
    this.choose = this.value
  },
  methods: {
    handleConfirm() {
      this.$emit('input', this.choose)
      this.$emit('confirm')
    },
    confirm() {
      this.pageNo = 1,
      this.total = 0
      this.list = []
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]()
    },
    reset() {
      this.form = {}
      this.pageNo = 1,
      this.total = 0
      this.list = []
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]()
    },
    getAllActive() {
      let ret = []
      this.list.map((val)=>{
        if(val.active){
          ret.push(val.info)
        }
      })
      return ret
    },
    async infiniteHandler($state) {
      await this.fetchList();
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    async fetchList() {
      let data = {
        ...this.params,
        "page.pageNo": this.pageNo,
        "page.pageSize": this.pageSize,
        materialCode: this.form.code || '',
        materialName: this.form.name || '',
        materialClass: this.form.classify || ''
      }
      let response = await fetchProductList(data)
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },
    async fetchProductType() {
      let data = {
        type: 'eb_material_class'
      }
      let response = await fetchProductTypeList(data)
      this.$set(this.rows, 0, {...this.rows[0], data: response.data})
    },
    handleChangeLoading(loading) {
      this.isLoading = loading
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-select-page {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  .form-wrap {
    padding-top: 12px;
  }
  .two-btn-container {
    width: 100%;
    height: 63px;
    box-sizing: border-box;
    padding: 12px 12px 13px;
    display: flex;
    justify-content: space-between;
    .btn-wrap {
      width: 170px;
    }
  }
  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
  }
}
</style>