<template>
  <div class="product-item-wrap">
    <div class="line">
      <div class="label-value">
        <div class="label">产品名称</div>
        <div class="value">{{info.materialName}}</div>
      </div>
    </div>
    <div class="line">
      <div class="label-value">
        <div class="label">产品编号</div>
        <div class="value">{{info.materialCode || info.materialId}}</div>
      </div>
    </div>
    <div class="line-flex">
      <div class="label-value">
        <div class="label">包重</div>
        <div class="value">{{info.bagWeight}}</div>
      </div>
      <div class="label-value">
        <div class="label">配比</div>
        <div class="value">{{info.matchEq}}</div>
      </div>
      <div class="label-value">
        <div class="label">单价</div>
        <div class="value">{{info.price || info.materialPrice}}</div>
      </div>
      <div class="label-value">
        <div class="label">数量</div>
        <div class="value input-value">
          <Field 
            v-if="!add && !useBackPrice"
            :disabled="!isEdit || parseInt(info.setmealFlag) === 0" 
            :formatter="formatter" 
            format-trigger="onBlur"
            class="input" 
            type="number" 
            v-model="num" 
            placeholder="请输入"
          />
          <div 
            class="input"
            v-if="add || useBackPrice"
          >
            {{info.applyNumber}}
          </div>
            {{info.materialUnit}}
        </div>
      </div>
      <div class="label-value">
        <div class="label">物料分类</div>
        <div class="value">{{info.loggr}}</div>
      </div>
      <div class="label-value">
        <div class="label">运费</div>
        <div class="value">{{tranMode === '10' ? (parseFloat(info.tranPrice || tranPrice) * (parseFloat(info.applyNumber) || num)).toFixed(2) : '--'}}</div>
      </div>
      <div class="label-value">
        <div class="label">满足天数</div>
        <div class="value">{{info.loggrName}}</div>
      </div>
      <!-- 使用返利 且不是特利包盒 -->
      <div class="label-value" v-if="useBackPrice">
        <div class="label">返利单价</div>
        <div class="value">{{info.zhPrice}}</div>
      </div>
      <!-- 使用返利 且不是特利包盒 -->
      <div class="label-value" v-if="useBackPrice">
        <div class="label">返利金额</div>
        <div class="value">{{info.zhAllprice}}</div>
      </div>
      <!-- 使用返利 且信息中有优惠金额字段 且不是特利包盒 -->
      <div class="label-value" v-if="info.discountAllprice">
        <div class="label">优惠金额</div>
        <div class="value">{{info.discountAllprice}}</div>
      </div>
      <div class="label-value">
        <div class="label">金额</div>
        <div class="value">{{useBackPrice ? info.allPrice : allPrice}}</div>
      </div>
    </div>
    <img v-if="isEdit" class="delete-icon" :src="DeleteIcon" @click="handleClickDelete" />
  </div>
</template>

<script>
import { Field } from 'vant'
import DeleteIcon from '@/assets/images/common/trash.png'

export default {
  components: {
    Field,
  },
  props: {
    value: {},
    info: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    numList: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    isEdit: {
      type: Boolean,
      default: true
    },
    tranMode: {
      type: String,
      default: '10'
    },
    tranPrice: {
      type: Number,
      default: 0
    },
    add: {
      type: Boolean,
      default: false
    },
    promotionList: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    giftList: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    useBackPrice: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.num = parseFloat(this.numList[this.info.materialCode]) || parseFloat(this.info.applyNumber) || ''
    // if (this.info.materialCode !== '1000000000000' && this.info.materialCode !== '1000000000001') {
    //   this.num = this.numList[this.info.materialCode] || ''
    // }
    // else {
    //   this.num = this.info.applyNumber
    // }
  } ,
  data() {
    return {
      DeleteIcon,
      num: ''
    }
  },
  watch: {
    num: {
      handler() {
        this.info.materialCode && this.$emit('changeNum', {materialCode: this.info.materialCode, num: this.num})
      },
      deep: true
    },
    info: {
      handler() {
        if (this.info.materialCode === '1000000000000' || this.info.materialCode === '1000000000001') {
          this.num = this.info.applyNumber
          this.$emit('changeNum', {materialCode: this.info.materialCode, num: this.num})
        } 
      },
      deep: true
    },
    numList: {
      handler() {
        this.num = this.numList[this.info.materialCode] >= 0 ? this.numList[this.info.materialCode] : ''
      },
      deep: true
    }
  },
  computed: {
    backPrice() {
      if (!this.useBackPrice || this.promotionList.indexOf(this.info.materialCode) !== -1 || this.promotionList.indexOf(this.info.materialId) !== -1 || this.isBagOrBox()) {
        return 0
      }
      else {
        if (this.info.price < 1500) {
          return 0
        }
        else if(this.info.price >= 1500 && this.info.price <= 1750) {
          return 1500
        }
        else {
          return this.info.price * 0.2
        }
      }
    },
    allPrice() {
      let price = this.info.price || this.info.materialPrice
      let tranPrice = this.tranPrice
      return (parseFloat(price) * this.num + (this.tranMode === '10' ? (parseFloat(tranPrice) * this.num || 0) : 0)).toFixed(2)
    }
  },
  methods: {
    isBagOrBox(code) {
      return code === '1000000000000' || code === '1000000000001'
    },
    formatter(value) {
      return this.isBagOrBox(this.info.materialCode) ? parseInt(value) : (parseFloat(value).toFixed(3) || 0)
    },
    handleClickDelete() {
      this.$emit('delete')
    }
  }
}
</script>

<style lang="scss" scoped>
.product-item-wrap {
  width: 100%;
  min-height: 200px;
  box-sizing: border-box;
  padding: 11px 24px 10px;
  background: white;
  border-bottom: 1px solid #f5f6f7;
  position: relative;
  .delete-icon {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 30px;
    bottom: 10px;
  }
  .line {
    width: 100%;
    min-height: 20px;
    line-height: 20px;
    font-size: 14px;
    display: flex;
    margin-bottom: 6px;
    .label-value {
      display: flex;
      flex: 1;
      .label {
        color: #818181;
        white-space: nowrap;
      }
      .value {
        color: #2C2C2C;
        margin-left: 8px;
        text-align: left;
      }
      .input-value {
        display: flex;
        ::v-deep.van-cell, .van-field {
          padding: 0;
          .van-field__body {
            height: 100%;
            .van-field__control {
              text-align: center;
              color: #818181;
              font-size: 14px;
            }
          }
        } 
        .input {
          width: 51px;
          height: 20px;
          border: 1px solid #CECECE;
          border-radius: 5px;
          margin-right: 6px;
          text-align: center;
        }
      }
    }
  }
  .line-flex {
    width: 100%;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    .label-value {
      width: 50%;
      flex-shrink: 0;
      margin-bottom: 6px;
      min-height: 20px;
      line-height: 20px;
      display: flex;
      .label {
        color: #818181;
        white-space: nowrap;
      }
      .value {
        color: #2C2C2C;
        margin-left: 8px;
        text-align: left;
      }
      .input-value {
        display: flex;
        ::v-deep.van-cell, .van-field {
          padding: 0;
          .van-field__body {
            height: 100%;
            .van-field__control {
              text-align: center;
              color: #818181;
              font-size: 14px;
            }
          }
        } 
        .input {
          width: 51px;
          height: 20px;
          border: 1px solid #CECECE;
          border-radius: 5px;
          margin-right: 6px;
          text-align: center;
        }
      }
    }
  }
}
</style>