<template>
  <div class="order-detail-page">
    <Header />
    <Progress class="progress" :nowProgress="progress" />
    <div class="block-component-container">
      <Row class="border-bottom" label="订单日期">
        <div class="date">{{date}}</div>
      </Row>
      <RowLink :links="links" :placeholder="true" placeholderText="查看" />
    </div>
    <Block :title="block1.title" :list="block1.list" :config="block1.config"></Block>
    <Block :title="block2.title" :list="block2.list" :config="block2.config"></Block>
    <Block :title="block3.title" :list="block3.list" :config="block3.config"></Block>
    <Block :title="block4.title" :list="block4.list" :config="block4.config" :blockName="block4.blockName"></Block>
    <Block :title="block5.title" :list="block5.list" :config="block5.config" :blockName="block5.blockName"></Block>
    <div class="btn-container">
      <CustomButton size="large" @click="$router.back()">返回</CustomButton>
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/index.vue'
import Progress from './components/progress.vue'
import RowLink from '@/components/select/rowLink.vue'
import Row from '@/components/select/row.vue'
import Block from './components/block.vue'
import CustomButton from '@/components/common/button/CustomButton.vue'
import { fetchOrderDetail } from '@/api/order'
import dayjs from 'dayjs'

export default {
  components: {
    Header,
    Progress,
    RowLink,
    Row,
    Block,
    CustomButton,
  },
  data() {
    return {
      date: '',
      id: '',
      links: [],
      infos: [],
      progress: '',
      block1: {
        title: '发运信息',
        list: [],
        config: [
          {label: '发货公司', field: 'factoryName', type: 'half', valueColor: ''},
          {label: '运输方式', field: 'tranModeName', type: 'half', valueColor: ''},
          {label: '整车发运', field: 'fhMode', mapping: {Y: '是', N: '否'}, type: 'half', valueColor: ''},
          {label: '参考运费', field: 'cankFee', type: 'half', valueColor: ''},
          {label: '运费', field: 'tranPrice', type: 'half', valueColor: ''},
          {label: '保价', field: 'baojia', type: 'half', valueColor: ''},
          {label: '缓发', field: 'delayFlag', mapping: {'1': '是', '0': '否'}, type: 'half', valueColor: ''},
          {label: '预计满足时间', field: 'delayDate', type: 'half', valueColor: ''},
          {label: '发票类型', field: 'ovatName', type: 'half', valueColor: ''},
          {label: '备注', field: 'remark', type: 'two', valueColor: ''},
        ]
      },
      block2: {
        title: '收货信息',
        list: [],
        config: [
          {label: '收货地址', field: 'tranAddress', type: 'one', valueColor: ''},
          {label: '收货人', field: 'tranConsignee', type: 'one', valueColor: ''},
          {label: '电话', field: 'tranTel', type: 'one', valueColor: '#006EFB'},
        ]
      },
      block3: {
        title: '卸货信息',
        config: [
          {label: '收货地址', field: 'detailAddress', type: 'one', valueColor: ''},
          {label: '收货人', field: 'tranConsignee', type: 'one', valueColor: ''},
          {label: '电话', field: 'tranTel', type: 'one', valueColor: '#006EFB'},
        ]
      },
      block4: {
        title: '产品明细',
        list: [],
        config: [
          {label: '物料编号', field: 'materialId', type: 'one', valueColor: ''},
          {label: '包重', field: 'bagWeight', type: 'half', valueColor: ''},
          {label: '申请数量', field: 'applyNumber', type: 'half', valueColor: ''},
          {label: '已发数量', field: 'sendNumber', type: 'half', valueColor: ''},
          {label: '配比', field: 'matchEq', type: 'half', valueColor: ''},
          {label: '运费', field: 'tranAllprice', type: 'half', valueColor: ''},
          {label: '物料分类', field: 'loggr', type: 'half', valueColor: ''},
          {label: '单价', field: 'materialPrice', type: 'half', valueColor: ''},
          {label: '返利单价', field: 'zhPrice', type: 'half', valueColor: ''},
          {label: '返利金额', field: 'zhAllprice', type: 'half', valueColor: ''},
          {label: '满足天数', field: 'loggrTxt', type: 'half', valueColor: ''},
          {label: '优惠金额', field: 'discountAllprice', type: 'half', valueColor: ''},
          {label: '金额', field: 'allPrice', type: 'half', valueColor: ''},
        ],
        blockName: 'materialName' // field
      },
      block5: {
        title: '随车发宣传品',
        list: [],
        config: [
          {label: '物料编号', field: 'materialId', type: 'one', valueColor: ''},
          {label: '预留号', field: 'rsnum', type: 'one', valueColor: ''},
          {label: '随车数量', field: 'applyNum', type: 'one', valueColor: ''},
        ],
        blockName: 'materialName' // field
      },
      sapCode: ''
    }
  },
  beforeMount() {
    this.id = this.$route.query.id 
    this.sapCode = this.$route.query.sapcode
    this.date = dayjs(this.$route.query.date, 'YYYYMMDD').format('YYYY-MM-DD')
    this.links = [
      {label: '承运人信息', path: `/info/carrierInfo?id=${this.id}`},
      {label: '电子发票信息', path: `/info/invoiceInfo?id=${this.sapCode}`},
      {label: '物流竞价', path: `/info/logisticsInfo?id=${this.id}`},
    ]
    this.fetchDetail()
  },
  methods: {
    async fetchDetail() {
      let response = await fetchOrderDetail({id: this.id})
      this.block1.list = [response.data]
      this.block2.list = [response.data]
      if (response.data.tranMode === '10') {
        this.block1.config.splice(3, 1)
      }
      else {
        this.block1.config.splice(4, 2)
      }
      if (response.data.tranMode === '20') {
        this.block3.list = response.data.deliverys || []
      }
      this.block4.list = response.data.details || []
      this.block5.list = response.data.publicitys || []
      this.progress = response.data.orderStatus || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.order-detail-page {
  width: 100%;
  min-height: 100vh;
  background: #FAFBFC;
  .progress {
    margin-top: 12px;
  }
  .block-component-container {
    margin-top: 12px;
  }
  .btn-container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    margin-top: 26px;
    margin-bottom: 23px;
  }
}
</style>