<template>
  <div class="detail-select-list">
    <BlockImageSelectItem 
      v-for="(item, index) in list" 
      :key="index" 
      :active="active[list[index][activeField]]" 
      :info="item" 
      @click.native="handleClick(index)"
    >
    </BlockImageSelectItem>  
  </div>
</template>

<script>
import BlockImageSelectItem from './blockImageSelectItem.vue'
import { fetchMaterialPrice } from '@/api/select'

export default {
  components: {
    BlockImageSelectItem
  },
  props: {
    value: {},
    list: {
      type: Array,
      default:()=> {
        return []
      }
    },
    activeField: {
      type: String,
      default: ''
    },
    params: {
      type: Object,
      default: ()=>{
        return {}
      }
    },
    single: {
      type: Boolean,
      default: false
    },
    checkPrice: {
      type: Boolean,
      default: true
    },
    chooseList: {
      type: Array,
      default: ()=>{
        return []
      }
    },
    setCodeList: {
      type: Array,
      default: ()=>{
        return []
      }
    }
  },
  data() {
    return {
      active: {},
      price: {}
    }
  },
  watch: {
    value() {
    }
  },
  mounted() {
    this.chooseList.map(item=>{
      this.active[item[this.activeField]] = true
    })
  },
  methods: {
    searchAlreadyInChooseList(code) {
      let retIndex = -1
      this.chooseList.map((item, index)=>{
        if (item.materialCode === code) {
          retIndex = index
        }
      })
      return retIndex
    },
    getAllActive() {
      let ret = []
      this.list.map(item=>{
        if (item[this.activeField] in this.active) {
          let index = this.searchAlreadyInChooseList(item[this.activeField])
          if ( index === -1) {
            ret.push({...item, ...this.price[item[this.activeField]], editable: true })
          }
          else {
            ret.push(this.chooseList[index])
          }
        }
      })
      return ret
    },
    async handleClick(index) {
      let clickItem = this.list[index][this.activeField]
      // 添加物料为特例包时，判断是否添加的物料里已经有了绑定的特例包
      if (clickItem === '1000000000000' || clickItem === '1000000000001') {
        let hasBoundFlag = this.chooseList.some(item=>{
          return parseInt(item.boundFlag) === 0
        })
        if (hasBoundFlag) {
          this.$toast('已选物料中包含捆绑物料，不可重复添加')
          return
        }
      }
      if (this.setCodeList.includes(clickItem)) {
        this.$toast('与套餐内物料冲突')
        return
      }
      if (clickItem in this.active) {
        this.$delete(this.price, clickItem)
        this.$delete(this.active, clickItem)
      }
      else {
        if (this.checkPrice) {
          this.$emit('loading', true)
          let response = [] 
          try {
            response = await fetchMaterialPrice({
              ...this.params,
              materialCode: this.list[index].materialCode
            })
          }
          catch(err){
            this.$toast(err.message)
            this.$emit('loading', false)
            return
          }
          this.$emit('loading', false)
          this.$set(this.price, clickItem, response.data)
        }
        if (this.single) {
          this.active = {}          
        }
        this.$set(this.active, clickItem, true)
      }
      this.$emit('input', this.getAllActive())
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-select-list {
  width: 100%;
}
</style>