<template>
  <div class="addorder-second-container">
    <Header type="" />
    <div class="title">
      贷款信息
    </div>
    <Form :rows="rows" v-model="form" />
    <Row label="产品明细" style="margin-top: 12px;">
      <div class="detail" @click="showProductDetail=true" v-if="pageStatus==='edit'">添加物料</div>
      <div class="detail" @click="showSetSelect=true" v-if="pageStatus==='edit' && showAddSet">添加套餐</div>
    </Row>
    <ProductItem 
      v-for="(item) in (orderSureProduct.length > 0 ? orderSureProduct : allProduct)" 
      :key="item.materialCode" 
      :info="item"
      :isEdit="pageStatus==='edit'" 
      :numList="numList"
      @changeNum="handleChangeNum"
      @delete="handleDeleteProduct(item.materialCode)"
      :tranMode="params.tranMode"
      :tranPrice="parseFloat(params.cankFee) || parseFloat(params.tranPrice)"
      :giftList="giftIdList"
      :promotionList="promotionList"
      :useBackPrice="useBackPrice"
      :add="(addBag || addBox) && (item.materialCode==='1000000000000' || item.materialCode==='1000000000001')"
    />
    <Row label="随车发宣传品" style="margin-top: 12px;" v-if="params.tranMode==='20'">
      <div class="detail" @click="handleAddPublicity" v-if="pageStatus==='edit'">添加</div>
    </Row>
    <PublicityItem  
      v-for="(item, index) in publicityList" 
      :key="index" 
      :info="item"
      :show="true" 
      @delete="handleDeletePublicity(index)"
    />
    <CustomButton 
      :class="{'no-product': allProduct.length === 0, 'has-product': product.length > 0 || publicityList.length > 0}" 
      :fixed="false" 
      :confirmLabel="pageStatus==='edit' ? '提交' : '确认订单'" 
      cancelLabel="上一步" 
      @confirm="handleConfirm" 
      @cancel="handleCancel"
      style="margin-bottom: 20px;"
    >
      <div class="all-count-container">
        <div class="order-all">
          订单总额
          <div class="all">{{all}}元</div>
        </div>
      </div>
    </CustomButton>
    <ProductDetailSelect 
      v-model="product" 
      v-if="showProductDetail" 
      @confirm="handleAddProduct" 
      :params="productParams" 
      :setCodeList="setCodeList"
    />
    <AddOrderThird 
      v-model="publicityList"
      v-if="showPublicity"
      @confirm="showPublicity=false"
    />
    <SetSelect
      v-model="set"
      v-if="showSetSelect"
      @confirm="handleSetSelectConfirm"
      :params="setParams"
      selectType="set"
      :single="true"
    />
    <!-- step: 0 -->
    <BoxBagDialog 
      v-if="showBoxBagDialog" 
      @addbox="handleAddBox"
      @addbag="handleAddBag"
      @cancel="handleBoxBagCancel"
    />
    <Promotion 
      title="选择促销方案"
      @cancel="handlePromotionCancel"
      @confirm="handlePromotionConfirm"
      v-if="showPromotion"
      :promotion="promotion"
    />
    <Dialog
      v-if="showDialog" 
      title="标题"   
      :showCancelBtn="true"
      @confirm="handleSetSelectConfirm"
      @cancel="showDialog=false" 
    >
      <template #content>
        <div class="set-number-container" >
          <div class="set-number-label">套餐数量:</div>
          <Field
            class="field"
            v-model="setNumber"
            type="digit"
          />
        </div>
      </template>
    </Dialog>
    <div class="loading-container" v-if="isLoading">
      <Loading color="#cd001f" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/header/headerWithNavigation/index.vue'
import Form from '@/components/common/form/index.vue'
import CustomButton from '@/components/common/button/FooterDoubleButton.vue'
import Row from '@/components/select/row.vue'
import ProductDetailSelect from '@/views/select/orderProductDetailSelect.vue'
import { fetchAccount, getProductInfo } from '@/api/order'
import { getDealerId } from '@/utils/user'
import SetSelect from '@/views/select/blockSelect.vue'
import { fetchSetDetail, saveOrder, checkOrderPromotion, confirmOrder, getSetAuth } from '@/api/order'
import { fetchProductList, fetchMaterialPrice } from '@/api/select'
import AddOrderThird from './addOrderThird.vue'
import ProductItem from './components/productItem.vue'
import { Loading, Field } from 'vant'
import PublicityItem from './components/publicity.vue'
import BoxBagDialog from './components/boxBagDialog.vue'
import Promotion from './components/promotion.vue'
import Dialog from '@/components/common/dialog/index.vue'
import { Dialog as VDialog } from 'vant'
/**
 * 特例包添加通过弹窗确认后，遍历生成
*/
export default {
  components: {
    Header,
    Form,
    CustomButton,
    Row,
    ProductDetailSelect,
    SetSelect,
    AddOrderThird,
    ProductItem,
    Loading,  
    PublicityItem,
    BoxBagDialog,
    Promotion,
    Dialog,
    Field,
  },
  data() {
    return {
      sendFlag: false,
      rows: [
        {label: '可用预收款总额', key: 'yfkPrice', type: 'input', disabled: true},
        {label: '可用返利金额', key: 'backPrice', type: 'input', disabled: true},
        {label: '运费未结算总额', key: 'tranAllprice', type: 'input', disabled: true},
      ],
      form: {
        yfkPrice: '0',
        backPrice: '0',
        tranAllprice: '0',
        tranPrice: '0'
      },
      params: {},
      setParams: {},
      product: [], // 产品列表
      set: [],
      showProductDetail: false,
      showSetSelect: false,
      productParams: {},
      productBag: {},
      productBox: {},
      publicityList: [],
      showPublicity: false,
      isLoading: false,
      numList: {},
      addBag: false,
      addBox: false,
      showBoxBagDialog: false,
      step: 0,
      pageStatus: 'edit', // edit   show\
      setProduct: [],          // 套餐产品列表
      showAddSet:false,        // 显示套餐添加窗口
      setNumber: '',            // 套餐数量
      // back price
      useBackPrice: false,     // 是否使用返利价格
      // promotion 
      promotion: [],           // 返回的套餐列表
      showPromotion: false,
      showDialog: false,       // 显示政策套餐弹窗
      promotionActive: {},     // 选中的政策
      giftIdList: [],          // 赠品ID列表
      promotionList: [],       // 优惠政策列表
      showGiftProduct: false,  // 是否显示赠品列表
      giftProductList: [],      // 赠品产品列表
      orderSureProduct: [],
      sureParams: {},
    }
  },
  watch: {
    numList: {
      handler() {
      },
      deep: true
    },
    setProduct: {
      handler() {
      },
      deep: true
    },
    product: {
      handler() {
      },
      deep: true
    }
  },
  async activated() {
    this.params = this.$route.params
    this.setParams = {
      "sellerId": getDealerId(),
      "setmealName": "",
      "factory": this.params.factory || '10'
    }
    this.productParams = {
      "dealerId": getDealerId(),
      "factory": this.params.factory || '10',
      "tranMode": this.params.tranMode || '10'
    }
    this.isLoading = true
    await this.fetchAccount()
    await this.fetchSetAuth()
    this.isLoading = false
  },
  computed: {
    setCodeList() {
      return this.setProduct.map(item=>{
        return item.materialCode || item.materialId
      })
    },
    all() {
      if (this.sureParams.orderAllPrice && this.pageStatus === 'show') {
        return this.sureParams.orderAllPrice
      }
      let ret = 0
      this.allProduct.map(item=>{
        let price = item.materialPrice ? parseFloat(item.materialPrice) : parseFloat(item.price)
        let num = this.numList[item.materialCode] ? parseFloat(this.numList[item.materialCode]) : 0
        let tranPrice = 0
        if (this.params.tranMode === '10') {
          tranPrice = parseFloat(this.params.cankFee || this.params.tranPrice) * num
        }
        ret +=  (price * num + tranPrice)
        ret = ret - this.getBackPrice(price) * num
      })
      return ret.toFixed(2)
    },
    allProduct() {
      let product = this.product.concat(this.setProduct)
      let boundNum = 0
      product.map(item=>{
        if (parseInt(item.boundFlag) === 0) {
          boundNum += (this.numList[item.materialCode] || 0)
        }
      })
      if (this.addBag && Object.keys(this.productBag).length > 0) {
        this.$set(this.numList, '1000000000000', boundNum * 20)
        product.unshift({...this.productBag, price: 7, materialPrice: 7, applyNumber: parseInt(boundNum * 20), editable: false})
      }
      else if (this.addBox && Object.keys(this.productBox).length > 0) {
        this.$set(this.numList, '1000000000001', boundNum * 2)
        product.unshift({...this.productBox, price: 70, materialPrice: 70, applyNumber: parseInt(boundNum * 2), editable: false})
      }
      if (this.showGiftProduct) {
        product = product.concat(this.giftProductList)
      }
      return product
    }
  },
  deactivated() {
    this.sendFlag = false;
    this.form = {
      yfkPrice: '0',
      backPrice: '0',
      tranAllprice: '0',
      tranPrice: '0'
    };
    this.params = {};
    this.setParams = {};
    this.product = [],
    this.set = [];
    this.showProductDetail = false;
    this.showSetSelect = false;
    this.productParams = {};
    this.productBag = [];
    this.productBox = [];
    this.publicityList = [];
    this.showPublicity = false;
    this.isLoading = false;
    this.numList = {};
    this.addBag = false;
    this.addBox = false;
    this.showBoxBagDialog = false;
    this.step = 0;
    this.pageStatus = 'edit'; // edit   show\
    this.showPromotion = false;
    this.setProduct = []; // 套餐列表
    this.showDialog = false;
    this.setNumber = '';
    this.promotionActive = {}
    this.giftIdList = [];
    this.promotionList = []
    this.promotion = [];
    this.giftProductList = []
    this.showGiftProduct = false
    this.useBackPrice = false
    this.showAddSet = false
    this.orderSureProduct = []
  },
  methods: {
    // step 0    获取添加特利包
    // step 1    查询促销
    // step 2    确认订单
    // step 3    提交订单
    async handleConfirm() {
      if (this.allProduct.length === 0) {
        this.$toast('请选择物料')
        return 
      }
      if (!this.allProductHasNumber()) {
        this.$toast('请填写物料数量')
        return
      }
      if (!this.isAllWholeBag()) {
        this.$toast('整包验证失败')
        return
      }
      if (this.allProduct.length > 7) {
        this.$toast('物料最多添加7个')
        return 
      }
      // step 0 确认特例功能包、盒添加
      if (this.step === 0) {
        if (this.isAllProductHasBoundFlag()){
          this.showBoxBagDialog = true
          return
        }
        else {
          this.step = 1
        }
      }
      // step 1 确认优惠政策的选择
      if (this.step === 1) {
        this.isLoading = true
        try {
          let response = await checkOrderPromotion({
            "sellerId": getDealerId(),
            "details": this.formatCheckParams()
          })
          this.isLoading = false
          if (parseInt(response.status) === -1 || (parseInt(response.status) === 0 && (!response.data || response.data.length === 0))) {
            // 没有优惠直接进入确认订单
            this.step = 2
          }
          else {
            this.promotion = response.data
            this.showPromotion = true
            return 
          }
        }
        catch(err) {
          this.$toast(err.message)
        }
      }
      // step 2 订单确认   上一步回到step 0
      if (this.step === 2) {
        this.showGiftProduct = true
        this.sureParams = {
          ...this.params,
          ...this.form,
          dealerId: getDealerId(),
          details: this.formateOrderSureParams() || [],
          boundFlag: this.addBag || this.addBox ? 0 : 1,
          publicitys: this.publicityList || [],
          deliveryDay: this.getDeliveryDay(),
          selectedPromotion: this.createPromotion(),
        }
        if (this.sureParams.tranMode === '10') {
          delete this.sureParams.cankFee
        }
        else {
          this.sureParams.tranPrice = 0
        }
        try {
          let day = this.getDeliveryDay()
          this.$toast(`最迟${day}天后到达`)
          this.isLoading = true
          this.useBackPrice = true
          this.sureParams.sellerId = this.sureParams.dealerId
          let response = await confirmOrder(this.sureParams)
          this.setOrderSureRes(response.data.details)
          this.pageStatus = 'show'
          this.sureParams = response.data
          this.isLoading = false
          this.step = 3
          return
        }
        catch(err) {
          this.isLoading = false
          this.$toast(err.message)
          this.pageStatus = 'edit'
          this.useBackPrice = false
        }
      }
      // 保存订单
      if (this.step === 3) {
        try {
          if (this.allProduct.length > 7) {
            this.$toast('物料最多添加7个')
            this.handleCancel()
            return 
          }
          if ((parseFloat(this.form.yfkPrice) + parseFloat(this.form.backPrice) - parseFloat(this.form.tranAllprice)) < this.sureParams.orderAllPrice) {
            this.$toast('可用预收款总额不足，无法添加新订单')
            return
          }
          this.isLoading = true
          let response = []
          try {
            response = await saveOrder(this.sureParams)
          }
          catch (err) {
            this.$toast(err.message || '创建失败')
            this.isLoading = false
            return
          }
          this.isLoading = false
          if (response && response.data && response.data.sapCode) {
            this.$toast(`创建成功，SAP订单号${response.data.sapCode}`)
          } else {
            if (response.msg) {
              this.$toast(response.msg.split(';')[0])
            }
            else {
              this.$toast('创建成功');
            }
          }
          setTimeout(()=>{
            this.$router.go(-2)
          }, 1500)
        }
        catch(err){
          this.isLoading = false
          this.$toast(err.message)
          this.pageStatus = 'edit'
          return
        }
      }
      return 
    },
    // 打开添加随车宣传品前逻辑判断
    handleAddPublicity() {
      if (this.allProduct.length === 0){
        this.$toast('请选择物料')
        return
      }
      if (!this.allProductHasNumber()) {
        this.$toast('请填写物料数量')
        return
      }
      this.showPublicity=true
    },
    // 格式化确认优惠政策参数
    formatCheckParams() {
      let ret = this.allProduct.map(item=>{
        return {
          materialId: item.materialCode || item.materialId,
          applyNumber: this.numList[item.materialCode] || '0'
        }
      }) 
      return ret     
    },
    // 上一步按钮回调
    handleCancel() {
      if (this.pageStatus === 'show') {
        this.pageStatus = 'edit'
        this.step = 0
        this.addBag = false
        this.addBox = false
        this.useBackPrice = false
        this.showGiftProduct = false
        this.giftProductList = []
        this.promotionActive = {}
        this.giftIdList = []
        this.promotionList = []
        this.promotion = []
        this.orderSureProduct = []
        this.removeGiftProduct()
        // this.removeGiftNum()
      }
      else {
        this.$router.back()
      }
    },
    // 修改数量回调
    handleChangeNum(e) {
      this.$set(this.numList, e.materialCode, parseFloat(e.num))
    },
    // 移除和套餐中重复的产品
    removeDuplicateProduct() {
      if (this.setProduct.length === 0) {
        return false
      }
      let length = this.product.length
      this.product = this.product.filter(item=>{
        let hasDuplicate =  !this.setProduct.some(setItem=>{
          return setItem.materialId === item.materialCode
        })
        return hasDuplicate
      })
      return length === this.product.length ? false : true
    },
    // 判断是否有绑定特例功能包
    hasBoundFlag(arr) {
      return arr.some(item=>{
        return item.boundFlag === 0
      })
    },
    // 移除特例功能包，特例功能盒
    removeBagBox() {
      let product = this.product.filter(item=>{
        return item.materialCode !== '1000000000000' && item.materialCode !== '1000000000001'
      })
      this.product = product
    },
    // 移除传入套餐列表的数量
    removeSetNumList() {
      this.setProduct.map(item=>{
        if (item.materialCode in this.numList) {
          this.$delete(this.numList, item.materialCode)
        }
      })
    },
    // 添加套餐数量
    addSetNumList(set) {
      set.map(item=>{
        this.$set(this.numList, item.materialCode, parseFloat(item.applyNumber))
      })
    },
    // 套餐选择回调
    async handleSetSelectConfirm() {
      if (this.set.length === 0) {
        this.setProduct = []
        this.setNumber = 0
        this.isLoading = false
        this.showSetSelect = false
        return
      }
      if (this.showDialog) {
        if (!this.setNumber){
          this.$toast('请填入套餐数量')
          return
        }
        this.showDialog = false
      }
      else {
        // 弹窗
        this.showDialog = true
        return
      }
      // 检查是否含有特例功能包/功能盒，有替换原先特例包/盒
      this.isLoading = true
      let response = []

      try {
        response = await fetchSetDetail({
          "setmealId": this.set[0].rowId,
          "applyNumber": this.setNumber,
          "sellerId": getDealerId(),
          "factory": this.params.factory || '10',
          "tranMode": this.params.tranMode || '20'
        })
      }
      catch (err) {
        this.isLoading = false
        this.$toast('网络请求失败')
        return
      }
      let product = []
      // 如有单个产品相同，删除单个产品，套餐产品无法修改数量
      this.setProduct = response.data 
      // 判断是否套餐产品和物料冲突,冲突则无法添加套餐产品 
      if (this.productListHasSetProduct()) {
        this.$toast('所选物料包含套餐产品,无法添加套餐')
        this.isLoading = false
        this.showSetSelect = false
        this.setProduct = []
        this.setNumber = 0
        return
      }
      // 删除自选产品中与套餐重复商品    
      // let hasRemove = this.removeDuplicateProduct()
      // if (hasRemove) {
      //   this.$toast('覆盖与套餐内冲突物料')
      // }
      // 删除和套餐相同产品的数量
      // this.removeSetNumList()
      response.data.map(item=>{
        product.push(fetchMaterialPrice({
          "dealerId": getDealerId(),
          "factory": this.params.factory || '10',    
          "tranMode": this.params.tranMode || '20',
          "materialCode": item.materialId,
        }))
      })
      let allRes = []
      // 查看套餐内是否有绑定特例包，有则删除原来添加的
      try {
        allRes = await Promise.all(product)
      }
      catch (err) {
        this.isLoading = false
        this.$toast('网络请求失败')
        return
      }
      let ret = []
      allRes.map((oneRes, index)=>{
        let temp = {...response.data[index], ...oneRes.data, setmealFlag: 0, materialCode: response.data[index].materialId}
        delete temp.yfkPrice
        delete temp.tranPrice
        delete temp.tranAllprice
        ret.push(temp)
      })
      this.addSetNumList(ret)
      if (this.hasBoundFlag(ret)) {
        this.removeBagBox()
      }
      this.setProduct = ret
      this.addBag = false
      this.addBox = false
      this.isLoading = false
      this.showSetSelect = false
    },
    // 获取账户信息
    async fetchAccount() {
      let response = await fetchAccount({
        "factory": this.params.factory || '10',
        "tranMode": this.params.tranMode || '20',
        "sellerId": getDealerId()
      })
      this.form = {
        yfkPrice: response.data.yfkPrice || '0',
        backPrice: response.data.backPrice || '0',
        tranAllprice: response.data.tranAllprice || '0',
        tranPrice: response.data.tranPrice || '0'
      }
    },
    // 获取是否具有添加套餐的权限
    async fetchSetAuth() {
      let response = await getSetAuth()
      if (response.msg === '0') {
        this.showAddSet = true
      }
      else {
        this.showAddSet = false
      }
    },
    // 根据赠品code列表添加赠品到产品列表
    async getGiftProductInfo() {
      this.isLoading = true
      let allPromise = this.giftIdList.map(item=>{
        return fetchMaterialPrice({
          "dealerId": getDealerId(),
          "factory": this.params.factory || '10',    
          "tranMode": this.params.tranMode || '20',
          "materialCode": item
        })
      }) 
      let allPriceRes = await Promise.all(allPromise)
      allPromise = this.giftIdList.map(item=>{
        return fetchProductList({
          "dealerId": getDealerId(), // 1000000000000
          "factory": this.params.factory,
          "page.pageSize": "1",
          "page.pageNo": "1",
          "materialCode": item
        })
      })
      let allProductRes = await Promise.all(allPromise)
      let giftProductList = allPriceRes.map((item, index)=>{
        let temp = {...allProductRes[index].data[0], ...item.data,}
        delete temp.yfkPrice
        delete temp.tranPrice
        delete temp.tranAllprice
        return temp
      })
      this.giftProductList = giftProductList
      this.isLoading = false
    },
    // 设置赠品数量
    setGiftNumList() {
      this.promotionList.map(item=>{
        if (item.giftId in this.numList) {
          this.$set(this.numList, item.giftId, this.numList[item.giftId] + parseFloat(item.promotionNumber))  
        }
        else {
          this.$set(this.numList, item.giftId, parseFloat(item.promotionNumber))
        }
      })
    },
    async fetchProductInfoAndPrice(code) {
      try {
        let productInfoResponse = await getProductInfo({
          "sellerId": getDealerId(), // 1000000000000
          "factory": this.params.factory || 10,
          "tranMode" : this.params.tranMode || 20,
          "materialCode": code,
        })
        let productPriceResponse = await fetchMaterialPrice({
          "dealerId": getDealerId(),
          "factory": this.params.factory || '10',    
          "tranMode": this.params.tranMode || '20',
          "materialCode": code,
        })
        return {...productInfoResponse.data, ...productPriceResponse.data, materialCode: code}
      }
      catch(err) {
        this.$toast(err.message)
        return {}
      }
    },
    // 获取特例功能包
    async fetchBag() {
      let code = '1000000000000'
      let product = {}
      try {
        this.isLoading = true
        product = await this.fetchProductInfoAndPrice(code)
        this.$set(this, 'productBag', product)
        this.isLoading = false
      }      
      catch(err) {
        this.isLoading = false
        this.$toast(err.message)
      }
    },
    // 获取特例功能盒
    async fetchBox() {
      let code = '1000000000001'
      let product = {}
      try {
        this.isLoading = true
        product = await this.fetchProductInfoAndPrice(code)
        this.$set(this, 'productBox', product)
        this.isLoading = false
      }      
      catch(err) {
        this.isLoading = false
        this.$toast(err.message)
      }
    },
    // 弹窗设置添加特例功能包
    async handleAddBag() {
      await this.fetchBag()
      this.addBag = true
      this.showBoxBagDialog = false
      this.step = 1
    },
    // 弹窗设置添加特例功能盒
    async handleAddBox() {
      await this.fetchBox()
      this.addBox = true
      this.showBoxBagDialog = false
      this.step = 1
    },
    // 弹窗设置取消添加特例功能包、盒
    handleBoxBagCancel() {
      this.showBoxBagDialog = false
      this.step = 1
      this.handleConfirm()
    },  
    // 取消优惠政策选择
    handlePromotionCancel() {
      this.clearPromotionParams()
      this.showPromotion=false
      this.step = 2
      this.handleConfirm()
    },
    // 是否所有产品中含有绑定
    isAllProductHasBoundFlag() {
      return this.allProduct.some(item=>{
        return item.boundFlag === '0'
      })
    },
    // 是否自选产品中含有绑定
    isProductHasBoundFlag() {
      return this.product.some(item=>{
        return parseInt(item.boundFlag) === 0
      })
    },
    // 移除促销政策赠品数量(但是不包括产品列表中的产品)
    removeGiftNum() {
      this.giftIdList.map(item=>{
        if (item in this.numList) {
          this.$delete(this.numList, item)
        }
      })
    },
    // 清理促销政策参数
    clearPromotionParams() {
      // this.removeGiftNum()
      this.promotionActive = {},     // 选中的政策
      this.giftIdList = [],          // 赠品ID列表
      this.promotionList = [],       // 优惠政策列表
      this.showGiftProduct = false,  // 是否显示赠品列表
      this.giftProductList = []      // 赠品产品列表
    },
    // 是否套餐产品中含有绑定
    isSetProductHasBoundFlag() {
      return this.setProduct.some(item=>{
        return parseInt(item.boundFlag) === 0
      })
    },
    // 促销政策选择回调
    async handlePromotionConfirm(activeIndex) {
      // 获取选中的促销
      this.promotionActive = activeIndex
      // 获取选中促销的赠品列表
      this.giftIdList = this.getGiftList(this.promotion)
      // 获取选中的促销品列表
      this.promotionList = this.getPromotionProduct(this.promotion)
      if (this.isProductGift()) {
        // 冲突就不会添加赠品数量， 如果不冲突会添加赠品数量，取消时直接移除
        this.clearPromotionParams()
        this.showPromotion = false
        this.step = 0
        this.$toast('已选商品和赠品冲突')
        return
      }
      // 添加赠品到列表
      await this.getGiftProductInfo()
      // this.setGiftNumList()
      this.showPromotion = false
      this.step = 2
      this.pageStatus = 'show'
      this.handleConfirm()
    },
    // 判断是否是套餐产品
    isSetProduct(code) {
      return this.setProduct.some(item=>{
        return item.materialCode === code || item.materialId === code
      })
    },
    // 检查是否产品列表中含有套餐产品
    productListHasSetProduct() {
      return this.product.some(item=>{
        return this.isSetProduct(item.materialCode)
      })
    },
    // 格式化确认订单参数
    formateOrderSureParams() {
      let product = []
      this.allProduct.map(item=>{
        if (!item.materialCode) {
          item.materialCode = item.materialId
        }
        if (this.giftIdList.indexOf(item.materialCode) !== -1) {
          return
        }
        item.applyNumber = parseFloat(this.numList[item.materialCode])
        let temp = {
          ...item,
          materialId: item.materialCode,
          materialPrice: item.price,
          setmealFlag: this.isSetProduct(item.materialCode) ? 0 : 1,
        }
        delete temp.editable
        delete temp.materialCode
        temp.tranPrice = this.params.tranMode === '10' ? parseFloat(this.params.cankFee) : 0
        temp.tranAllprice = parseFloat(temp.tranPrice) * parseFloat(temp.applyNumber)
        temp.allPrice = parseInt(temp.applyNumber) * (parseFloat(temp.materialPrice) + parseFloat(temp.tranPrice))
        product.push(temp)
      })
      return product
    },
    // 判断是否所有产品都有数量
    allProductHasNumber() {
      return this.allProduct.every(item=>{
        return !!this.numList[item.materialCode]
      })
    },
    // 处理添加产品逻辑
    handleAddProduct(){
      // 添加物料时判断是否物料绑定特例包，如果绑定，且原先手动添加了特例包，删除原先特例包
      if (this.isProductHasBoundFlag() || this.isSetProductHasBoundFlag()) {
        this.product = this.product.filter(item=>{
          return item.materialCode !== '1000000000000' && item.materialCode !== '1000000000001'
        })
      }
      // 如果物料是存在套餐中的则删除存在与套餐内相同的产品
      let hasRemove = this.removeDuplicateProduct()
      if (hasRemove) {
        this.$toast('已添加非套餐中的产品')
      }
      this.addBag = false
      this.addBox = false
      this.step = 0
      this.showProductDetail = false
    },
    // 获取缓发天数
    getDeliveryDay() {
      let day = 0
      if (this.params.delayDay) {
        day += parseInt(this.params.delayDay)
      }
      let maxDay = 0
      this.allProduct.map(item=>{
        if (!item.loggrName) {
          return
        }
        let result = item.loggrName.match(/\d+/)
        let tempDay = 0
        if (result && result.length > 0) {
          tempDay = parseInt(item.loggrName.match(/\d+/)[0])
        }
        if (tempDay > maxDay){
          maxDay = tempDay
        }
      })
      return day + maxDay
    },
    // 整包验证
    isAllWholeBag() {
      let ret = true
      this.allProduct.map(item=>{
        if (item.materialCode !== '1000000000000' && item.materialCode !== '1000000000001') {
          if (!this.numList[item.materialCode]) {
            ret = false
          }
          else {
            if (((this.numList[item.materialCode] * 1000) / parseInt(item.bagWeight)) % 1 !== 0) {
              ret = false
            }
          }
        }
      })
      return ret
    },
    // 获取返利价格
    getBackPrice(price) {
      if (!this.useBackPrice) {
        return 0
      }
      else {
        if (price < 1500) {
          return 0
        }
        else if(price >= 1500 && price <= 1750) {
          return 1500
        }
        else {
          return price * 0.2
        }
      }
    },
    // 获取赠品列表
    getGiftList(data) {
      let ret = []
      data.map((item, index)=>{
        if (index in this.promotionActive){
          // 去重
          if (ret.indexOf(item.giftId) === -1) {
            ret.push(item.giftId)
          }
        }
      })
      return ret
    },
    // 产品中是否存在赠品
    isProductGift() {
      return this.allProduct.some(item=>{
        return this.giftIdList.indexOf(item.materialCode) !== -1
      })
    },
    // 创建促销字段
    createPromotion() {
      let ret = []
      for(let x in this.promotionActive) {
        let item = this.promotion[parseInt(x)]
        ret.push(`${item.itemId}@${item.materialId}@${item.giftId}`)
      }
      return ret.join(',')
    },
    // 获取促销产品code列表
    getPromotionProduct(data) {
      let ret = []
      data.map((item, index)=>{
        if (index in this.promotionActive){
          // 去重
          if (ret.indexOf(item.materialId) === -1) {
            ret.push(item.materialId)
          }
        }
      })
      return ret
    },
    // 在产品列表中查找， 返回索引
    findInProduct(code) {
      let ret = 0
      this.product.map((item, index)=>{
        if (item.materialCode === code) {
          ret = index
        }
      })
      return ret
    },
    // 删除产品
    handleDeleteProduct(code) {
      VDialog.confirm({
        title: '提示',
        message: '是否删除物料',
      })
      .then(() => {
        // on confirm
        if (this.isSetProduct(code)) {
          this.$toast('此产品为套餐产品无法删除')
          return
        }
        if ((this.addBag || this.addBox) && (code === '1000000000000' || code === '1000000000001')) {
          this.addBag = false
          this.addBox = false
          this.step = 0
        }
        else {
          let index = this.findInProduct(code)
          if (this.isLastBound()) {
            if (this.addBag) {
              // 将最后一个捆绑物料的数量赋值给功能包
              this.product.push({...this.productBag, applyNumber: parseFloat(this.numList[code]) * 20})
              this.$set(this.numList, '1000000000000', parseFloat(this.numList[code]) * 20)
            }
            else if(this.addBox) {
              this.product.push({...this.productBox, applyNumber: parseFloat(this.numList[code]) * 2})
              this.$set(this.numList, '1000000000001', parseFloat(this.numList[code]) * 2)
            }
            this.addBag = false
            this.addBox = false
          }
          this.$delete(this.product, index)
          this.$delete(this.numList, code)
        }
        this.$toast('删除成功')
      })
      .catch(() => {
        // on cancel
      });
    },
    setOrderSureRes(details) {
      this.orderSureProduct = details
      // let productDiscountAllPrice = {}
      // let zhPrice = {}
      // let zhAllPrice = {}
      // details.map && details.map(item=>{
      //   productDiscountAllPrice[item.materialId] = item.discountAllprice || 0
      //   zhPrice[item.materialId] = item.zhPrice || 0
      //   zhAllPrice[item.materialId] = item.zhAllPrice || 0
      // })
      // this.product = this.product.map(item=>{
      //   return {
      //     ...item, 
      //     discountAllprice: productDiscountAllPrice[item.materialId] || '0',
      //     zhPrice: zhPrice[item.materialId] || 0,
      //     zhAllPrice: zhAllPrice[item.materialId] || 0
      //   }
      // })
      // this.setProduct = this.setProduct.map(item=>{
      //   return {
      //     ...item, 
      //     discountAllprice: productDiscountAllPrice[item.materialCode] || '0',
      //     zhPrice: zhPrice[item.materialId] || 0,
      //     zhAllPrice: zhAllPrice[item.materialId] || 0
      //   }
      // })
    },
    // 不显示赠品
    removeGiftProduct() {
      this.showGiftProduct = false
    },
    // 判断是否有选择促销政策
    isChoosePromotion() {
      return Object.keys(this.promotionActive).length > 0
    },
    // 随车发宣传品删除回调
    handleDeletePublicity(index) {
      VDialog.confirm({
        title: '提示',
        message: '是否删除随车发宣传品',
      })
      .then(() => {
        // on confirm
        this.$delete(this.publicityList, index)
        this.$toast('删除成功')
      })
      .catch(() => {
        // on cancel
      });
    },
    // 遍历获取绑定标志的物料
    isLastBound() { 
      let boundList = this.product.filter(item=>{
        return item.boundFlag === '0'
      })
      return boundList.length === 1
    }
  },
}
</script>

<style lang="scss" scoped>
.addorder-second-container {
  background: #fafbfc;
  min-height: 100vh;
  .detail {
    font-size: 14px;
    color: #91939A;
  }
  .title {
    font-size: 14px;
    text-align: left;
    margin-top: -12px;
    height: 43px;
    box-sizing: border-box;
    padding-top: 12px;
    padding-left: 24px;
  }
  .all-count-container {
    width: 100%;
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: flex-end;
    color: #91939A;
    font-size: 14px;
    .label {
      display: flex;
      align-items: center;
      .circle {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 7px;
        border: 2px solid #CD001F;
        &.active {
          background: #CD001F;
        }
      }
    }
    .order-all {
      display: flex;
      align-items: center;
      .all {
        color: #CD001F;
        margin-left: 5px;
      }
    }
  }
  .no-product {
    position: fixed;
    bottom: 20px;
    left: 0;
  }
  .has-product {
    margin-top: 20px;
    padding-bottom: 30px;
  }
  .loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
  }
  .set-number-container {
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    .set-number-label {
      line-height: 20px;
      color: #333;
      font-size: 14px;
      white-space: nowrap;
    }
    .field {
      width: 40px;
      height: 20px;
      background: #ffffff;
      border-radius: 5px;
      padding: 2px 8px;
      border: 1px solid #333;
      margin-left: 10px;
      ::v-deep .van-field__body {
        height: 100%;
        .van-field__control {
          text-align: right;
        }
      }
      &::after {
        border: none;
      }
    }
  }
}
</style>  